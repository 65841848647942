import React from "react";

const Video = () => {
  return (
    <div className="">
      <video
        autoPlay
        muted
        loop
        style={{
          width: "100%",
          borderRadius: "30px",
          border: "2px solid #006B8B",
        }}
      >
        <source
          src="https://res.cloudinary.com/oluwatoyinah/video/upload/v1668043845/test/myadmissionlink-portal-features_lvt4pb.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> 
    </div>
  );
};

export default Video;
