import React, { useState } from "react";
import BigButton from "./BigButton";
import EvaluateForm from "./EvaluateForm";
import axios from "../api/axios"; 
import Swal from 'sweetalert2'

const CONTACT_URL = '/contact/message'

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    let data = {
        name: fullName,
        email,
        message,
    };

    if ( fullName === '' || email === "" || message === "") {
      setIsSubmitting(false)
      return
    }
    try {
        const response = await axios.post(CONTACT_URL, data, {
            headers: {
                'Content-Type' : 'application/json',
                'Accept': "*"  
            }, 
        }) 
        if (response.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Yayyy...',
            text: 'Submitted successfully',
            showConfirmButton: false,
            timer: 1500   
          })
        } 
        setFullName('')
        setEmail('')
        setMessage('')
        setIsSubmitting(false)
    } 
    catch(err) {
        console.log(err)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oops...',
          text: 'An error occured, Retry',
          showConfirmButton: false,
          timer: 1500   
        })
        setIsSubmitting(false)
        setFullName('')
        setEmail('')
        setMessage('')
    }

  } 

  return (
    <section className="container-fluid py-5 contact">
      <div className="container">
        <div className="row g-3">
          <div className="contact-header col-12 mb-lg-5 mb-3 order-1 order-lg-0">
            <div className="col-lg-4">
              <h2>Contact Us</h2>
              <p>
                feel free to reach out to us, let’s help you get into school
                abroad
              </p>
            </div>
          </div>
          <div className="col-lg-7 contact-form order-2 order-lg-1">
            <div className="h-100">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <input
                    type="text"
                    name=""
                    placeholder="Full name"
                    id=""
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <input
                    type="email"
                    name=""
                    id=""
                    value={email}
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <textarea
                    name=""
                    placeholder="Message"
                    id=""
                    cols="30"
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                <div>
                  <BigButton text="Send" color="#fff" bgColor="#006B8B" isSubmitting={isSubmitting} />
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 col-md-8 mx-auto order-0 order-lg-2 my-5 my-lg-0">
            <div className="contact-form h-100">
              <div className="contact-form-box p-3 p-sm-5 py-5">
                <div className="contact-form-box-header ">
                  <h5>Evaluate yourself</h5>
                  <p className="col-md-10 mx-auto">
                    know what country and Academic Level you are Eligible for.
                  </p>
                </div>

                <div className="contact-form-box-body">
                  <EvaluateForm
                    btnText="Evaluate"
                    btnColor="#fff"
                    btnBgColor="#ECA633"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
