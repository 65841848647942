import React from 'react' 
import BigButton from '../../../Components/BigButton'

const VisaStatementOfPurpose = () => {
  return (
    <>
        <div className="apply-pay-box-header mb-5">
            <div>
                
                <h5 className='mb-2'>Make Purchase</h5>
                <div className="d-md-flex justify-content-between">
                    <div className='d-flex gap-3 align-items-center mb-3 mb-md-0'>  
                        <div>
                            <h6>Statement of Purpose</h6>
                            <p>Visa Explanation Letter</p>
                        </div>  
                    </div>
                    <div className='cash-box text-center'>
                        <span className='mb-0'>&#x20A6; 150,000
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="apply-pay-box-body">
            <div className="col-lg-7 col-xl-6 mx-auto d-flex justify-content-center text-center">
                <div> 
                    <p>Well detailed and relevant Visa explanation letter that increases your visa chance by 100%</p> 
 
                </div>
            </div>
        </div> 

        <div className='mt-5 text-center'>
            <BigButton text="Pay Now" color="#fff" bgColor="#1FAF38" />
        </div>
    </>
  )
}

export default VisaStatementOfPurpose