import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom' 

const ApplyPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className="apply">
      <header className="apply-header">
        <div className="container">
          <button className="title-btn">Apply Now</button>
        </div>
      </header>
      <Outlet />
    </div>
  )
}

export default ApplyPage