import React from 'react'
import UniAgency from '../assets/images/uniageny 1.png'
import USCG from '../assets/images/icef-us 1.png'
import BritishCouncil from '../assets/images/british-council.png'
import ICEF from "../assets/images/icef-logo 1.png"
import ApplyBoard from '../assets/images/applyboard 1.png'
import ICEFAgency from '../assets/images/agencystat.png'
import BigButton from './BigButton'
import { Link } from 'react-router-dom'

const Certification = () => {
  return (
    <section className="container-fluid certification py-5">
        <div className="container">
            <div className="row">
                <div className="col-md-9 mx-auto text-center mb-5">
                    <h2>Our Certifications</h2>
                    <p>We are Trained and Certified by the Higest International Education Agencies</p>
                </div>
                <div className="col-12 mb-5">
                    <div className="row g-5 g-lg-3">
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={UniAgency} alt="" /></div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={USCG} alt="" /></div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={BritishCouncil} alt="" /></div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={ICEF} alt="" /></div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={ApplyBoard} alt="" /></div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-6 text-center d-flex align-items-center justify-content-center"><img src={ICEFAgency} alt="" /></div>
                    </div>
                </div>
                <div className='text-center'>
                    <Link to='/processing'>
                        <BigButton text="Get Access" color="#fff" bgColor="#006B8B" />
                    </Link>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Certification