import React from 'react'
import Phone from '../assets/images/phone.png'

const BlueBanner = () => {
  return (
    <section className="container-fluid info-section">
       <div className="container py-2">
          <div className='d-flex justify-content-end gap-3 align-items-center'>
             <div className=''>
               <img src={Phone} alt="phone" />
             </div>
             <div className='d-flex gap-3 align-items-center'>
                <span>+2349075819985</span> 
                <span>+2349127862906</span> 
             </div>
          </div>
       </div>
    </section>
  )
}

export default BlueBanner