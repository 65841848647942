import React from 'react'
import UK from '../../../assets/images/UK.png'
import BigButton from '../../../Components/BigButton'

const AdmissionData = ["Profiling and Academic Evaluation", "One Free Statement of Purpose", "Maximum of two applications", "Access to our Certified Student Counsellors", "Course Selection", "University Selection", "Documentation", "Filling of Admission Application Forms", "Scholarship & funding Application as applicable"
] 

const UkAdmissionProcessing = ({academicLevel}) => {
  return (
    <>
        <div className="apply-pay-box-header mb-5">
            <div>
                <h5 className='mb-2'>Make Purchase</h5>
                <div className="d-md-flex justify-content-between">
                    <div className='d-flex gap-3 align-items-center mb-3 mb-md-0'>
                        
                            <img src={UK} alt="" />
                            <div>
                                <h6>UK</h6>
                                <p>{academicLevel === "Undergraduate Application" ? "Undergraduate" : "Postgraduate"}-Admission</p>
                            </div>  
                    </div>
                    <div className='cash-box text-center'>
                        <span className='mb-0'>&#x20A6; 40,000
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="apply-pay-box-body">
            <div className="col-lg-7 col-xl-6 mx-auto d-flex justify-content-center text-center">
                <div>  
                    <ul className='col-lg-8 mx-auto ps-0' style={{listStyleType: "none"}}>
                        
                        {
                            AdmissionData.map((_, i) => (
                                <li key={i}>• &nbsp; {_}</li>
                            ))
                        } 
                    </ul> 
                </div>
            </div>
        </div> 

        <div className='mt-5 text-center'>
            <a href="https://portal.myadmissionlink.com/">
                <BigButton text="Pay Now" color="#fff" bgColor="#1FAF38" />
            </a>
        </div>
    </>
  )
}

export default UkAdmissionProcessing