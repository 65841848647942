import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import BigButton from "./BigButton";
import axios from "../api/axios";
import Swal from 'sweetalert2'
const EVALUATE_URL = '/evaluation/leads'

const EvaluateModal = ({ setModalActive }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

   const handleSubmit = async e => {
      e.preventDefault()
      setIsSubmitting(true)

      let data = {
        name, email,
        phone_number: phoneNumber
      }

      if( name === '' || email === '' || phoneNumber === '') {
        setIsSubmitting(false)
        return
      }

      try {
        const response = await axios.post(EVALUATE_URL, data, {
          headers: {
            'Content-Type' : 'application/json',
            'Accept': '*'
          }
        })

        if (response.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Yayyy...',
            text: 'Submitted successfully',
            showConfirmButton: false,
            timer: 1500   
          })
        } 
        setIsSubmitting(false)
        setName('')
        setEmail('')
        setPhoneNumber('')
      }
      catch(err) {
        console.log(err)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oops...',
          text: 'An error occured, Retry',
          showConfirmButton: false,
          timer: 1500   
        })
        setIsSubmitting(false)
        setName('')
        setEmail('')
        setPhoneNumber('')
      }
   }




  return (
    <div className="evaluate-modal-container">
      <div className="evaluate-modal-box container">
        <div
          className="col-lg-10 col-xl-9 col-md-12 evaluate-modal mx-auto pt-5 p-md-5 p-3"
          style={{ minHeight: "300px" }}
        > 
        <FaTimes 
              onClick={() => setModalActive(false)}
              className="close-btn"
            />
          <div className="evaluate-modal-header mb-3">
            <h3>Evaluation</h3>
            <p className="mb-0">
              Based on the details provided, you may be eligible for an Undergraduate Year 1 course.
            </p>
          </div>
          <div className="evaluate-modal-body"> 
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label htmlFor="">Name</label>
                <input type="text" name="" value={name} className="form-control" id="" placeholder="Name" onChange={(e) => setName(e.target.value)} />
              </div>

              <div className="mb-2">
                <label htmlFor="">Email Address</label>
                <input type="email" name="" value={email} className="form-control" id="" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="mb-md-5 mb-3">
                <label htmlFor="">Phone Number</label>
                <input
                  type="tel"
                  name="" className="form-control"
                  id=""
                  value={phoneNumber}
                  placeholder="e.g 070877455673"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className=''> 
                <BigButton text='Submit' bgColor='#ECA633' color='#fff' isSubmitting={isSubmitting} />
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluateModal;
