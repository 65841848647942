import React from 'react'
import { Link } from 'react-router-dom'
import Naija from '../assets/images/flag.png'
import UK from '../assets/images/UK.png'
import Twitter from '../assets/images/twitter.png'
import FaceBook from '../assets/images/facebook.png'
import LinkedIn from '../assets/images/linkedin.png'
import Instagram from '../assets/images/insta.png'
import WhatsApp from '../assets/images/whatsapp.png'

const Footer = () => {
  return (
    <footer className="footer container-fluid py-5">
        <div className='container'>
           <div className="row">
              <ul className='footer-list mb-4'>
                  <li className="col">
                      <Link to="/">
                        Company
                      </Link>
                  </li>
                  <li className="col">
                      <Link to="/">
                        Book Consultation
                      </Link>
                  </li>
                  <li className="col">
                      <Link to="/processing">
                        Application Form
                      </Link>
                  </li>
                  <li className="col">
                      <Link to="/">
                        Contact
                      </Link>
                  </li>
                  <li className="col">
                      <Link to="/">
                        Privacy Policy
                      </Link>
                  </li>
              </ul>

              <div className='location mb-2 d-flex fle-md-row flex-column justify-content-center gap-md-3 gap-2 align-items-center'>
                  <div>
                    <div className='mb-2'>
                      <img src={Naija} alt="flag" style={{width: "45px"}} />
                    </div>
                    <div>
                      <p className='mb-2'> 
                        <span>Head Office: 61, Fate Road,</span> 
                        <span> Opposite BOVAS, Ilorin, Nigeria.</span>
                      </p>
                    </div>
                  </div>
 
                  <div>
                    <div className='mb-2'>
                      <img src={UK} alt="flag" style={{width: "45px"}} />
                    </div> 

                    <div> 
                      <p> 
                        <span>28-30 Sea Road, Boscombe,</span>
                        <span> Bournermouth BH5 1DF.</span>  
                      </p>
                    </div>
                  </div>
              </div>

              <div className="footer-social-icons mb-2">
                  <div className="d-flex gap-3 justify-content-center">
                      <Link to="/"><img src={FaceBook} alt="" /></Link>
                      <Link to="/"><img src={Twitter} alt="" /></Link>
                      <Link to="/"><img src={LinkedIn} alt="" /></Link>
                      <Link to="/"><img src={Instagram} alt="" /></Link>
                  </div>
              </div>

              <div className='footer-email mb-2'>
                <a href='mailto:admission@myadmissionlink.com'>admission@myadmissionlink.com</a>
              </div>

              <div className="footer-number mb-2">
                  <div className=" d-flex gap-3 align-items-center justify-content-center">
                      {/* <span>+2349070124191</span>    */}
                      <span>+447771030909</span>
                  </div>
              </div>

              <div className="footer-chat mb-3">
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                      <div>
                        <img src={WhatsApp} alt="" />
                      </div>
                      <div>
                        <p className='mb-0'>+2349075819985</p>
                        <p className='mb-0'>+2349127862906</p>
                      </div>
                  </div>
              </div>
           </div>
        </div>
    </footer>
  )
}

export default Footer