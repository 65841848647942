import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Certification from "../Components/Certification";
import Stats from "../Components/Stats";
import Girl from "../assets/images/girl.png";
import BigLogo from "../assets/images/1cef.png";
import BigButton from "../Components/BigButton";
import CapGirl from "../assets/images/cap-girl.png";
import Hand from "../assets/images/hand.png";
import Contact from "../Components/Contact";
import Testimonials from "../Components/Testimonials";
import BlueRing from "../assets/images/blue-ring.png";
import StudyRing from "../assets/images/study-ring.png";
import { Swiper, SwiperSlide } from "swiper/react";
import GirlBg from "../assets/images/girl-bg.png";
import GirlRing from "../assets/images/girl-ring.png";
import BookGirl from "../assets/images/couple-girls.jpg";
import FreeRing from "../assets/images/free-ring.png";
import CertBadge from "../assets/images/cert-badge.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Video from "../Components/Video";

const Home = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(1); 
 
  const changeSlide = () => {
    // iterates activeSection by 1 on every slide change
    if (activeSection >= 3) {
      setActiveSection(1);
    } else {
      //restart the iteration when activesection is more than 3
      setActiveSection(activeSection + 1);
    } 
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  //texts that will be changed based on the activeSection when the slide function runs
  const headerText = {
    1: "There is a world out there full of possibilities. Your dream is our mission",
    2: "get access to over 70,000 courses in over 2,000 + schools",
    3: "Let's make your dream of STUDYING ABROAD a REALITY",
  };

  return (
    <div className="home">
      <header className="home-header container-fluid d-md-block d-none">
        <div className="mt-3 ms-3">
          <div className="row px-0">
            <div className="col-md-5 col-lg-4 home-header-blue-section d-flex  justify-content-center bg">
              <div className="d-flex justify-content-center">
                <div
                  className="col-md-9 col-xl-8 ps-lg-5"
                  style={{ position: "relative" }}
                >
                  <img src={StudyRing} alt="" className="study-ring" />
                  <h1 className="text-uppercase">
                    STUDY <br /> ABROAD
                  </h1>
                  <p> 
                    {headerText[activeSection]}
                  </p>
                  <Link to="processing">
                    <button className="access-btn mt-3">Get Access</button>
                  </Link>
                </div>
              </div>
              <img src={BlueRing} alt="" className="blue-ring" />
              <img src={CertBadge} alt="" className="cert-badge" />
            </div>
            <div className="col-md-7 col-lg-8 home-header-carousel px-0">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                onSlideChange={changeSlide}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper h-100"
              >
                <SwiperSlide className="slide-div slide-div-one">
                  <div></div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slide-div slide-div-two"></div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slide-div slide-div-three"></div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </header>
      <header className="home-header-mobile container-fluid gx-0 d-md-none">
        <div className="mt-3 home-header-mobile-carousel px-0">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            onSlideChange={changeSlide}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
            style={{ height: "100%" }}
          >
            <SwiperSlide className="slide-div slide-div-one">
              <div>
                <p style={{ visibility: "hidden" }}>slide</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-div slide-div-two">
                <p style={{ visibility: "hidden" }}>slide</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-div slide-div-three">
                <p style={{ visibility: "hidden" }}>slide</p>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="home-header-mobile-carousel-text row">
            <div className="col-10 col-sm-9 mx-auto home-header-mobile-carousel-text-box">
              <div className="pt-3 px-1">
                <h1 className="text-uppercase">
                  STUDY <br /> ABROAD
                </h1>
                <p>{headerText[activeSection]}</p>
                <Link to="processing">
                  <button className="access-btn mt-3">Get Access</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Stats />
      <section className="container-fluid py-5 get-started">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mb-4 mx-auto text-center">
              <h2>How to get started</h2>
              <p>we help students get direct link to universities abroad.</p>
            </div>
            <div className="col-lg-10 col-xl-9 mx-auto">
              <Video />
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid pt-5 pb-4 mt-5 benefits">
        <div className="container" style={{ position: "relative" }}>
          <button className="title-btn benefit-btn">Our Benefits</button>
          <div className="row">
            {/* py-5 p-sm-5 */}
            <div className="col-lg-9 mx-auto benefits-box px-4">
              <div className="row gx-5">
                <div className="col-lg-6 col-md-8 mx-auto benefits-box-text py-5 p-sm-5">
                  <div>
                    <h6 className="mb-3">Straight Access</h6>
                  </div>
                  <div>
                    <img src={BigLogo} alt="" />
                    <div className="my-4">
                      <h2>No Restrictions!</h2>
                      <p>
                        Access to over 70,000 courses and over 2,000 school - no
                        restrictions
                      </p>
                    </div>
                    <div className="mt-3 mb-md-5">
                      <Link to="processing">
                        <BigButton
                          text="Get Access"
                          color="#FFF"
                          bgColor="#006B8B"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 py-5 p-sm-5 col-md-8 mx-auto benefits-box-image d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <img
                    src={GirlRing}
                    alt=""
                    className="girl-ring-bg"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "-10px",
                      width: "70%",
                    }}
                  />
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={Girl}
                      alt=""
                      width="90%"
                      className="girl"
                      style={{ zIndex: "10" }}
                    />
                    <img
                      src={GirlBg}
                      alt=""
                      style={{
                        position: "absolute",
                        top: "-15px",
                        right: "15px",
                        zIndex: "1",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid pb-5 free-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto free-section-container">
              <div className="row g-3">
                <div className="col-md-6 mx-auto">
                  <div className="free-card p-0 h-100">
                    <img src={CapGirl} alt="" width="100%" />
                    <div
                      className="free-card-body px-4 py-5 p-sm-5"
                      style={{ position: "relative" }}
                    >
                      <div>
                        <h3>
                          IT’S <span className="tx-yellow">FREE!!!</span>
                        </h3>
                        <ul>
                          <li>free eligibility evaluation</li>
                          <li>Free statement of purpose</li>
                          <li>free 3 backup courses per application</li>
                        </ul>
                      </div>
                      <img
                        src={FreeRing}
                        alt=""
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                          width: "70%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mx-auto">
                  <div className="access-card h-100">
                    <img className="hand" src={Hand} alt="" />
                    <div className="p-4 py-5 p-sm-5">
                      <h3>We got you!</h3>
                      <p>We have expert consultants -ICEF Certified. </p>
                      <p>we get weekly updates on your application.</p>
                      <div>
                        <Link to="processing">
                          <BigButton
                            text="Get Access"
                            color="#fff"
                            bgColor="rgba(255, 255, 255, 0.2)"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid py-5 procedure px-0">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mb-4 mx-auto text-center procedure-header">
              <h2>Our Procedure</h2>
              <p>
                how we get you into the right school abroad, it really easy for
                us.
              </p>
            </div>
          </div>
        </div> 
        <div className="container-fluid procedure-body py-5">
          <div className="container">
            <div className="row py-md-5">
              <div className="col-lg-9 mx-auto procedure-body-box">
                  <div className="row gx-5">
                  <div className="col-md-6 col-lg-5 pt-5 pt-md-0">
                    <div className="img-box">
                      <img src={BookGirl} className='mb-3 mb-md-0' alt="" />
                      <button className="title-btn step">Step</button>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-7 mb-5">
                  <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      loop={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }} 
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper h-100 pb-5 pb-md-0"
                    >
                      {
                        ['Evaluation', 'Document Review', 'Student Profile Creation', 'Matching Student Profile', 'Get Letter of Acceptance'].map((_, i) => (
                          <SwiperSlide key={i} className="d-flex align-items-center" style={{width: "100%"}}> 
                          
                              <div className="col-md-7">  
                                <div className="">
                                  <h3 className="mb-2">{_}</h3> 
                                </div>
                              </div> 
                          </SwiperSlide>
                        ))
                      }  
                    </Swiper>
                  </div>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <Certification />
      <Contact />
    </div>
  );
};

export default Home;
