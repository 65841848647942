import React from 'react'
import BigButton from '../../../Components/BigButton'

const BookConsultation = () => {
  return (
    <>
        <div className="apply-pay-box-header mb-5">
            <div> 
                <h5 className='mb-2'>Make Purchase</h5>
                <div className="d-md-flex justify-content-between">
                    <div className='d-flex gap-3 align-items-center mb-3 mb-md-0'> 
                            <div>
                                <h6>Book Consultation</h6> 
                            </div> 

                        
                    </div>
                    <div className='cash-box text-center'>
                        <span className='mb-0'>&#x20A6; 20,000
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="apply-pay-box-body">
            <div className="col-lg-7 col-xl-6 mx-auto d-flex justify-content-center text-center">
                <div> 
                        <p>Book Consultation with our certified ICEF student counsellor and British Education UK Agent at ₦20,000 for 30 minutes</p>  
                </div>
            </div>
        </div> 

        <div className='mt-5 text-center'>
            <a href="https://portal.myadmissionlink.com/">
                <BigButton text="Pay Now" color="#fff" bgColor="#1FAF38" />
            </a>
        </div>
    </>
  )
}

export default BookConsultation