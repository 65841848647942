import React from 'react' 
import { useContext } from 'react'
import DataContext from '../../context/DataContext' 
import VisaStatementOfPurpose from './applyComponents/VisaStatementOfPurpose'
import BookConsultation from './applyComponents/BookConsultation'
import AdmissionStatementOfPurpose from './applyComponents/AdmissionStatementOfPurpose'
import DirectVisaApplication from './applyComponents/DirectVisaApplication'
import CanadaVisaProcessing from './applyComponents/CanadaVisaProcessing'
import CanadaAdmissionProcessing from './applyComponents/CanadaAdmissionProcessing'
import UkVisaProcessing from './applyComponents/UkVisaProcessing'
import UkAdmissionProcessing from './applyComponents/UkAdmissionProcessing'
import AustraliaVisaProcessing from './applyComponents/AustraliaVisaProcessing'
import AustraliaAdmissionProcessing from './applyComponents/AustraliaAdmissionProcessing'
import UsaVisaProcessing from './applyComponents/UsaVisaProcessing'
import UsaAdmissionProcessing from './applyComponents/UsaAdmissionProcessing'
import PolandVisaProcessing from './applyComponents/PolandVisaProcessing'
import PolandAdmissionProcessing from './applyComponents/PolandAdmissionProcessing'
import { Navigate } from 'react-router-dom'
 

const ApplyPay = () => {
    const { service, servicePaid, academicLevel, statementOfPurpose } = useContext(DataContext)


    //when page is refreshed, state gets cleared. This handles navigation to processing page when state is cleared
    if (service === "") { 
        return <Navigate to='/processing' />
    }
    
  return (
    <section className="container-fluid py-5 mt-md-5 apply-pay">
        <div className="container"> 
            <div className="row">
                <div className="col-lg-10 col-xl-9 mx-auto">
                    <div className='apply-pay-box p-5'>
                        {
                            service === "Statement of Purpose" && statementOfPurpose === "Visa Explanation Letter" && <VisaStatementOfPurpose />
                        }

                        {
                            service === "Statement of Purpose" && statementOfPurpose === "Admission Statement of Purpose" && <AdmissionStatementOfPurpose />
                        }

                        {
                            service === "Book Consultation" && <BookConsultation />
                        }

                        {
                            service === "Direct Visa Application only" && <DirectVisaApplication />
                        }

                        {
                            service === "Canada" && servicePaid === "Visa Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <CanadaVisaProcessing academicLevel={academicLevel} />
                        }

                        {
                            service === "Canada" && servicePaid === "Admission Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <CanadaAdmissionProcessing academicLevel={academicLevel} />
                        } 
                        
                        {
                            service === "UK" && servicePaid === "Visa Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <UkVisaProcessing academicLevel={academicLevel} />
                        }

                        {
                            service === "UK" && servicePaid === "Admission Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <UkAdmissionProcessing academicLevel={academicLevel} />
                        } 

                        {
                            service === "Australia" && servicePaid === "Visa Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <AustraliaVisaProcessing academicLevel={academicLevel} />
                        }
                        
                        {
                            service === "Australia" && servicePaid === "Admission Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <AustraliaAdmissionProcessing academicLevel={academicLevel} />
                        } 
                        
                        {
                            service === "USA" && servicePaid === "Visa Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <UsaVisaProcessing academicLevel={academicLevel} />
                        }
                        
                        {
                            service === "USA" && servicePaid === "Admission Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <UsaAdmissionProcessing academicLevel={academicLevel} />
                        } 
                        
                        {
                            service === "Poland" && servicePaid === "Visa Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <PolandVisaProcessing academicLevel={academicLevel} />
                        }
                        
                        {
                            service === "Poland" && servicePaid === "Admission Processing" && (academicLevel === "Undergraduate Application" || academicLevel === "Postgraduate Application") && <PolandAdmissionProcessing academicLevel={academicLevel} />
                        } 
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ApplyPay