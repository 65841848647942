import React from 'react'
import { FaCaretUp } from 'react-icons/fa'

const BackToTop = ({toTop}) => {
  return (
    <button className='back-to-top' onClick={toTop} style={{position: 'fixed', bottom: "20px", right: "20px"}}>
        <FaCaretUp />
    </button>
  )
}

export default BackToTop