import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../assets/images/logo.png';
import Naija from '../assets/images/flag.png'
import Twitter from '../assets/images/twitter.png'
import FaceBook from '../assets/images/facebook.png'
import LinkedIn from '../assets/images/linkedin.png'
import Instagram from '../assets/images/insta.png' 
import {FaAngleRight, FaAngleDoubleRight} from 'react-icons/fa'

const MobileNav = ({closeNav, navActive}) => {
    let activeStyle = {
        fontWeight: "900",
        color: "#ECA633"
      };

  return (
    <div className={`mobile-nav ${navActive ? 'active' : ''}`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <img src={Logo} alt="" className='inline-flex logo' />
            <div className='close-hamburg d-flex d-lg-none justify-content-end align-items-center gap-1' onClick={closeNav}> 
                <p className='mb-0 fw-bold'>CLOSE</p>
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

        <div className='mobile-nav-content-contact text-end mb-5'> 
            <div className='location mb-2'>
                  <div className='mb-2'>
                    <img src={Naija} alt="flag" style={{width: "45px"}} />
                  </div>
                  <div>
                    <p> 
                      <span>Head Office: 61, Fate Road,</span> 
                      <span> Opposite BOVAS, Ilorin, Nigeria.</span>
                    </p>
                  </div>
              </div>

              <div className="footer-social-icons">
                  <div className="d-flex gap-3 justify-content-end">
                      <Link to="/"><img src={FaceBook} alt="" /></Link>
                      <Link to="/"><img src={Twitter} alt="" /></Link>
                      <Link to="/"><img src={LinkedIn} alt="" /></Link>
                      <Link to="/"><img src={Instagram} alt="" /></Link>
                  </div>
              </div>
 
            </div>

        <div className="mobile-nav-content d-flex justify-content-between flex-column">
            <div style={{minHeight: "55vh"}} className="mobile-nav-content-links">
                <div className=''>
                    {/* <h6>LINKS</h6> */}

                    <div>
                        <ul className='mb-0 ms-0'> 
                            <li>
                                <NavLink 
                                    to='/' 
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeNav}>
                                        <FaAngleRight />    Home    
                                </NavLink>
                            </li>
                            <li>
                                <NavLink 
                                    to='/about' 
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeNav}>
                                        <FaAngleRight /> Who we are
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-bs-toggle="collapse" href="#studyAbroad" role="button" aria-expanded="false" aria-controls="cp">
                                <FaAngleRight /> Study Abroad
                                </a>
                                <ul className="collapse mobile-collapse row ps-3" id="studyAbroad">
                                    <li onClick={closeNav} className='m-0 col-6'><Link to='/study-abroad/uk' className="dropdown-item"> <FaAngleDoubleRight /> UK</Link></li>
                                    <li onClick={closeNav} className='m-0 col-6'><Link to='/study-abroad/canada' className="dropdown-item"> <FaAngleDoubleRight /> Canada</Link></li>
                                    <li onClick={closeNav} className='m-0 col-6'><Link to='/study-abroad/poland' className="dropdown-item"> <FaAngleDoubleRight /> Poland</Link></li> 
                                    <li onClick={closeNav} className='m-0 col-6'><Link to='/study-abroad/usa' className="dropdown-item"> <FaAngleDoubleRight /> USA</Link></li>
                                    <li onClick={closeNav} className='m-0 col-6'><Link to='/study-abroad/australia' className="dropdown-item"> <FaAngleDoubleRight /> Australia</Link></li> 
                                </ul>
                            </li> 
                            <li>
                                <NavLink 
                                    to='/processing' 
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeNav}>
                                       <FaAngleRight /> Apply Now
                                </NavLink>
                            </li>
                            <li>
                                <NavLink 
                                    to='/evaluate' 
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeNav}>
                                      <FaAngleRight />  Evaluation
                                </NavLink>
                            </li> 
                            
                            <li>
                                <a href="https://myadmissionlink.com/blog/" target='_blank'><FaAngleRight /> Blog</a> 
                            </li>

                            <li>
                                <a href="https://portal.myadmissionlink.com/" target='_blank'><FaAngleRight /> Portal</a> 
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mt-auto"></div>
            
        </div>
     </div>
  )
}

export default MobileNav