import React from 'react'
import USA from '../../../assets/images/USA-flag.png'
import BigButton from '../../../Components/BigButton'


const AdmissionData = [
    "Profiling and Eliibility Evaluation",
    "Admission Documentation",
    "One Free Statement of Purpose",
    "School Selection",
    "Course Selection",
    "Access to our Paid Student Portal",
    "Access to Live Chat for fast Communication",
    "Weekly Status Feedback",
    "Error Free Application",
    "Scholarship and Funding Application as applicable",
]
const UsaAdmissionProcessing = ({academicLevel}) => {
  return (
    <>
        <div className="apply-pay-box-header mb-5">
            <div>
                <h5 className='mb-2'>Make Purchase</h5>
                <div className="d-md-flex justify-content-between">
                    <div className='d-flex gap-3 align-items-center mb-3 mb-md-0'>
                        
                            <img src={USA} alt="" />
                            <div>
                                <h6>USA</h6>
                                <p>{academicLevel === "Undergraduate Application" ? "Undergraduate" : "Postgraduate"}-Admission</p>
                            </div>  
                    </div>
                    <div className='cash-box text-center'>
                        <span className='mb-0'>&#x20A6; 100,000
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="apply-pay-box-body">
            <div className="col-lg-7 mx-auto d-flex justify-content-center text-center">
                <div> 
                        <p>Payment can be made in 2 installments, &#x20A6; 50,000 to start processing and &#x20A6; 50,000 when admission letter is out</p> 

                    <ul className='col-lg-8 mx-auto ps-0' style={{listStyleType: "none"}}>
                        
                        {
                            AdmissionData.map((_, i) => (
                                <li key={i}>• &nbsp; {_}</li>
                            ))
                        }
                    </ul> 
                </div>
            </div>
        </div> 

        <div className='mt-5 text-center'>
            <a href="https://portal.myadmissionlink.com/">
                <BigButton text="Pay Now" color="#fff" bgColor="#1FAF38" />
            </a>
        </div>
    </>
  )
}

export default UsaAdmissionProcessing