import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'; 
import EvaluateForm from '../Components/EvaluateForm';

const EvaluatePage = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className="evaluate">
      <header className="evaluate-header">
        <div className="container">
          <button className="title-btn">Evaluate</button>
        </div>
      </header>
      <section className="container-fluid evaluate-form py-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 col-xl-6 mx-auto">
                 <div className="evaluate-form-box p-3 p-md-5 py-5">
                   <div className="evaluate-form-box-header">
                      <h5>Evaluate yourself</h5>
                      <p className='col-md-10 mx-auto'>know what country and Academic Level you are Eligible for.</p>
                   </div>

                   <div className="evaluate-form-box-body pb-md-5">
                       
                      <EvaluateForm btnText="Evaluate" btnBgColor="#197A97" btnColor="#fff" />
                   </div>
                 </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  )
}

export default EvaluatePage