import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BlueBanner from "./Components/BlueBanner";
import Footer from "./Components/Footer";
import MobileNav from "./Components/MobileNav";
import NavBar from "./Components/NavBar";
import About from "./Pages/About";
import ApplyForm from "./Pages/apply/ApplyForm";
import ApplyPay from "./Pages/apply/ApplyPay";
import ApplyPage from "./Pages/ApplyPage";
import EvaluatePage from "./Pages/EvaluatePage";
import Home from "./Pages/Home"; 
import PageNotFound from "./Pages/PageNotFound";
import StudyAbroadCountry from "./Pages/StudyAbroadCountry";
import { DataProvider } from "./context/DataContext";
import BackToTop from "./Components/BackToTop";

function App() {
  const [navActive, setNavActive] = useState(false)

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const closeNav = () => {
    setNavActive(false)
  }

  const openNav = () => {
    setNavActive(true)
  }

  const goToTop = () => {
    window.scrollTo(0,0)
  }

  
  return ( 
    <DataProvider>
      <BlueBanner />
      <NavBar openNav={openNav} navActive={navActive} />
      <main className="main">
          <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/about" element={<About />} /> 
              <Route path="/study-abroad/:country" element={<StudyAbroadCountry />} />
              <Route path="/evaluate" element={<EvaluatePage /> } /> 
              <Route path="/processing" element={<ApplyPage />}>
                <Route index element={<ApplyForm />} />
                <Route path="pay" element={<ApplyPay />} />
              </Route> 
              <Route path="*" element={<PageNotFound />} />
          </Routes>
      </main>
      <Footer />
      <MobileNav closeNav={closeNav} navActive={navActive} />
      <BackToTop toTop={goToTop} />
      </DataProvider>

  );
}

export default App;
