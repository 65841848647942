import React, { useEffect } from 'react'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import ArrowRight from '../../assets/images/arrow-right.png'
import { useContext } from 'react'
import DataContext from '../../context/DataContext'

const ApplyForm = () => {  
  const { service, setService, servicePaid, setServicePaid, academicLevel, setAcademicLevel, statementOfPurpose, setStatementOfPurpose } = useContext(DataContext) 
  const [navigate, setNavigate] = useState(false)

  useEffect(() => {  
     setService('')
     setAcademicLevel('')
     setServicePaid('')
     setStatementOfPurpose('')
  }, [])
  
  const ApplyPay = (e) => {
    e.preventDefault()
 

    if (service === '') {
      setNavigate(false)
      alert("Select a service") 
    } else if(service === "Book Consultation" || service === "Direct Visa Application only") { 
      setNavigate(true)
    } else if(service !== '' && (servicePaid  !== '' && academicLevel  !== '')) { 
      setNavigate(true)
    } else if (service === "Statement of Purpose" && statementOfPurpose !== "") { 
      setNavigate(true)
    }
  }

  if(navigate) {
    return <Navigate to='pay' />
  }

  return (
    <section className="container-fluid apply-form py-5 mt-md-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 col-xl-6 mx-auto">
                 <div className="apply-form-box p-3 p-md-5 pt-5 pb-md-5">
                   <div className="apply-form-box-header">
                      <h5>Purchase</h5>
                      <p className='col-md-10 mx-auto'>Kindly select the offer you would like to purchase. </p>
                   </div>

                   <form onSubmit={ApplyPay} className="apply-form-box-body pb-5">
                      <div className='mb-5'>
                        <div className='mb-3'>
                          <select name="" id="" className='form-select' onChange={(e) => setService(e.target.value)}>
                            <option value="" selected disabled>What service can we provide you with?</option>
                            <option value="Canada">Canada</option>
                            <option value="UK">UK</option>
                            <option value="Australia">Australia</option>
                            <option value="USA">USA</option>
                            <option value="Poland">Poland</option>
                            <option value="Direct Visa Application only">Direct Visa Application only</option>
                            <option value="Book Consultation">Book Consultation</option>
                            <option value="Statement of Purpose">Statement of Purpose</option>
                          </select>
                        </div>

                        {
                          (service === "Canada" || service === "UK" || service === "Australia" || service === "USA" || service === "Poland") && (
                            <div className='mb-3'>
                              <select name="" id="" className='form-select' onChange={(e) => setServicePaid(e.target.value)}>
                                <option value="" selected disabled>Which service are you paying for?</option>
                                <option value="Visa Processing">Visa processing</option>
                                <option value="Admission Processing">Admission processing</option>
                              </select>
                            </div>
                          )
                        }

                        {
                          (service === "Canada" || service === "UK" || service === "Australia" || service === "USA" || service === "Poland") && (
                            <div className='mb-3'>
                              <select name="" id="" className='form-select' onChange={(e) => setAcademicLevel(e.target.value)}>
                                <option value="" selected disabled>Which  academic level are you applying for?</option>
                                <option value="Undergraduate Application">Undergraduate Application</option>
                                <option value="Postgraduate Application">Postgraduate Application</option>
                              </select>
                            </div>
                          )
                        }

                        

                        {
                          service === "Statement of Purpose" && (
                            <div className='mb-3'>
                              <select name="" id="" className='form-select' onChange={(e) => setStatementOfPurpose(e.target.value)}>
                                <option value="" selected disabled>Select the Statement of Purpose</option>
                                <option value="Admission Statement of Purpose">Admission Statement of Purpose</option>
                                <option value="Visa Explanation Letter">Visa Explanation Letter</option>
                              </select>
                            </div>
                          )
                        }
                      </div>

                      <div className='pt-5'>
                         {/* <Link to='pay'> */}
                                <button className='big-btn' style={{backgroundColor: `rgba(255, 255, 255, 0.1)`, color: `#fff`}}>
                                    <span className='me-2'>Next</span>
                                    <img src={ArrowRight} alt="" />
                                </button>
                            {/* </Link>  */}
                        </div>
                   </form>
                 </div>
              </div>
            </div>
          </div>
      </section>
  )
}

export default ApplyForm