import React, { useEffect } from 'react'
import Stats from '../Components/Stats'
import Team from '../assets/images/team.png'
import Center from '../assets/images/center.png'
import { useLocation } from 'react-router-dom'

const About = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className='about'>
      <header className='about-header'>
         <div className="container">
          <button className="title-btn about-btn">About US</button>
         </div>
      </header>
      <section className="container-fluid who-we-are py-5 my-5">
        <div className="container">
           <div className="row">
              <div className="col-md-10 mx-auto text-start text-md-center">
                 <div className="text-center">
                    <h3>Who we are</h3>
                 </div>
                 <p className=''>Myadmissionlink Educational Consult is a Nigerian and British incorporated Educational Consult. We help international students to realize their dream of getting qualitative education and opportunities via study route in their destination countries. Myadmissionlink Educational Consult is certified by the highest ranking institutions in the world- ICEF Trained Student Counselor (ITAC 1711), British Council Education UK Certified Agent (GAL/23698) among other certifications and trainings.</p>
              </div>
           </div>
        </div>
      </section>

      <section className="container-fluid pt-5 mb-4 team-section">
        <div className="container">
          <div className="row g-3">
             <div className="col-lg-6">
              <div className='team-section-image'>
                <img src={Team} alt="" style={{width: "90%"}} />
                <button className="title-btn team-btn">Our Team</button>
              </div>
             </div>
             <div className="col-lg-6 d-flex align-items-center">
              <div className='team-section-text'>
                <p>As part of our <span className="tx-yellow">commitment</span> to deliver first class services & training to our clients, we offer a set of professional services for optimum client satisfaction. At the heart of all our services is our dedication to the success of our partners and students. We operate with a team of formal and committed Senior Education Advisers /counselors who understand the need of the students and their parents / sponsors. We firmly believe in best customer service to the students and our client institutions. Our <span className="tx-yellow">strongly client centered approach</span> to counseling means; we give clients the fullest possible information on the available options to help them make sensible decisions.</p>
              </div>
             </div>
          </div>
        </div>
      </section>
      <section className="container-fluid pb-5 mt-4 mb-5 client-satisfaction">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 d-flex align-items-center order-1 order-lg-0">
              <div className='text-center'>
                <h3 className='col-lg-7 mx-auto'>At the Centre of our services is the <span className="tx-yellow">client satisfaction</span></h3>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className='client-satisfaction-image'>
                <img src={Center} alt="" style={{width: "90%"}} />
                <button className="title-btn value-btn">Our Values</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Stats />
    </div>
  )
}

export default About