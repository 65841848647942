import { createContext, useState } from "react";

const DataContext = createContext({});


export const DataProvider = ({children}) => {
    const [service, setService] = useState('')
    const [servicePaid, setServicePaid] = useState('')
    const [academicLevel, setAcademicLevel] = useState('')
    const [statementOfPurpose, setStatementOfPurpose] = useState('')

    return (
        <DataContext.Provider value={{
            service, setService, servicePaid, setServicePaid, academicLevel, setAcademicLevel, statementOfPurpose, setStatementOfPurpose
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;