import React, { useState } from 'react'
import BigButton from './BigButton'
import EvaluateModal from './EvaluateModal'

const EvaluateForm = ({btnColor, btnText, btnBgColor}) => {
    const [location, setLocation] = useState('')
    const [highestAcademicQualification, setHighestAcademicQualification] = useState('')
    const [postgraduateType, setPostgraduateType] = useState('')
    const [modalActive, setModalActive] = useState(false)

    const openModal = (e) => {
        e.preventDefault()

        // If a either a location or academic qualification has not been picked dont show the modal
        if(location === '' || highestAcademicQualification === "") return
        
        setModalActive(true)
    }
    
  return (
    <>
    <form action="" onSubmit={openModal}>
        <div className='mb-3'>
            <div className='mb-3'>
            <select name="" id="" className='form-select' onChange={(e) => setLocation(e.target.value)}>
                <option value selected disabled>Location</option>
                <option value="Canada">Canada</option>
                <option value="UK">UK</option>
                <option value="Australia">Australia</option>
                <option value="USA">USA</option>
                <option value="Poland">Poland</option>
            </select>
            </div>

            <div className='mb-3'>
                <select name="" id="" className='form-select' onChange={(e) => setHighestAcademicQualification(e.target.value)}>
                    <option value="" selected disabled>Highest Academic Qualification</option>
                    <option value="Undergraduate">Undergraduate</option>
                    <option value="Postgraduate">Postgraduate</option>
                </select>
            </div>

            {
                highestAcademicQualification === "Undergraduate" && (
                    <div className='mb-3'>
                        <select name="" id="" className='form-select'>
                            <option value="" selected disabled>Undergraduate Certification</option>
                            <option value="">O'Level result (WAEC, NECO and GCE)</option>
                            <option value="">Ordinary National Diploma (OND)</option>
                            <option value="">A'Levels (Cambridge, JUPEB and IJMB)</option>
                            <option value="">Undergraduate Diploma</option>
                        </select>
                    </div>
                )
            }

            {
                highestAcademicQualification === "Postgraduate" && (
                    <div className='mb-3'>
                        <select name="" id="" className='form-select' onChange={(e) => setPostgraduateType(e.target.value)}>
                            <option value="" selected disabled>Postgraduate Type</option>
                            <option value="UniversityGraduate">University Graduate</option>
                            <option value="PolytechnicGraduate">Polytechnic Graduate</option>
                        </select>
                    </div>
                )
            }

            {
                highestAcademicQualification === "Postgraduate" && postgraduateType === "UniversityGraduate" && (
                    <div className='mb-3'>
                        <select name="" id="" className='form-select'>
                            <option value="" selected disabled>University Graduate grade</option>
                            <option value="">First Class</option>
                            <option value="">Second Class Upper</option>
                            <option value="">Second Class Lower</option>
                            <option value="">Third Class</option>
                            <option value="">Post Graduate Diploma (PGD)</option>
                        </select>
                    </div> 
                )
            }

            {
                highestAcademicQualification === "Postgraduate" && postgraduateType === "PolytechnicGraduate"  && (
                    <div className='mb-3'>
                        <select name="" id="" className='form-select'>
                            <option value="" selected disabled>Polytechnic Graduate grade</option>
                            <option value="">Distinction</option>
                            <option value="">Upper Credit</option>
                            <option value="">Lower Credit</option>
                            <option value="">Pass</option>
                        </select>
                    </div> 
                )
            }
 
        </div>

        <div className='pt-3'> 
            <BigButton text={btnText} bgColor={btnBgColor} color={btnColor} />
        </div>
    </form>
    {
        modalActive && <EvaluateModal setModalActive={setModalActive} /> 
    }
    </>
  )
}

export default EvaluateForm