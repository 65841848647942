import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Poland from '../assets/images/poland.png'
import Australia from '../assets/images/australia.jpg'
import USA from '../assets/images/usa.png'
import UK from '../assets/images/about-header.png'
import Canada from '../assets/images/study.png'
import Hat from '../assets/images/hat.png'
// import BigButton from '../Components/BigButton'


const canadaData = [
   {
      title: "UNDERGRADUATE",
      offers: ["O level result (WAEC/NECO)","Data page of international passport","Admission  Application fee"],
      bgColor: "#ECA633"
   },
   {
      title: "MASTERS/POST GRADUATE DIPLOMA",
      offers: ["O level result (WAEC/NECO)", "Data page of international passport", "Admission Application fee(FREE for UK & AUSTRALIA)", "Degree/HND certificate/", "Transcripts", "Statement of purpose(written by us)", "CV/Resume"],
      bgColor: "#1FAF38"
   },
   {
      title: "POSTGRADUATE (PHD)",
      offers: ["All requirements in (A & B)", "Masters certificate and transcript", "Research Proposal"],
      bgColor: "#006B8B"
   }
]


const ukData = [
   {
      title: "UNDERGRADUATE",
      offers: ["O level result (WAEC/NECO)","Data page of international passport","Admission  Application fee"," Filled Bio Data Form","English Test (IELTS)"],
      bgColor: "#ECA633"
   },
   {
      title: "MASTERS/POST GRADUATE DIPLOMA",
      offers: ["O level result (WAEC/NECO)", "Data page of international passport", "Admission Application fee(FREE for UK & AUSTRALIA)", "Degree/HND certificate/", "Transcripts", "Statement of purpose(written by us)", "CV/Resume"],
      bgColor: "#1FAF38"
   },
   {
      title: "POSTGRADUATE (PHD)",
      offers: ["All requirements in (A & B)", "Masters certificate and transcript", "Research Proposal"],
      bgColor: "#006B8B"
   }
]


const polandData = [
   {
      title: "UNDERGRADUATE",
      offers: ["O level result (WAEC/NECO)","Data page of international passport", "A white background passport photograph", "Admission  Application fee"," Filled Bio Data Form"],
      bgColor: "#ECA633"
   },
   {
      title: "MASTERS/POST GRADUATE DIPLOMA",
      offers: ["O level result (WAEC/NECO)", "Data page of international passport", "Admission Application fee(FREE for UK & AUSTRALIA)", "Degree/HND certificate/", "Transcripts", "Statement of purpose(written by us)", "CV/Resume"],
      bgColor: "#1FAF38"
   },
   {
      title: "POSTGRADUATE (PHD)",
      offers: ["All requirements in (A & B)", "Masters certificate and transcript", "Research Proposal"],
      bgColor: "#006B8B"
   }
]



const usaData = [
   {
      title: "UNDERGRADUATE",
      offers: ["O level result (WAEC/NECO)","Data page of international passport","Admission  Application fee"," Filled Bio Data Form"],
      bgColor: "#ECA633"
   },
   {
      title: "MASTERS/POST GRADUATE DIPLOMA",
      offers: ["O level result (WAEC/NECO)", "Data page of international passport", "Admission Application fee(FREE for UK & AUSTRALIA)", "Degree/HND certificate/", "Transcripts", "Statement of purpose(written by us)", "CV/Resume"],
      bgColor: "#1FAF38"
   },
   {
      title: "POSTGRADUATE (PHD)",
      offers: ["All requirements in (A & B)", "Masters certificate and transcript", "Research Proposal"],
      bgColor: "#006B8B"
   }
]


const australiaData = [
   {
      title: "UNDERGRADUATE",
      offers: ["O level result (WAEC/NECO)","Data page of international passport", "English Test (IELTS)"],
      bgColor: "#ECA633"
   },
   {
      title: "MASTERS/POST GRADUATE DIPLOMA",
      offers: ["O level result (WAEC/NECO)", "Data page of international passport", "Admission Application fee(FREE for UK & AUSTRALIA)", "Undergraduate Degree certificate/HND", "Transcripts", "Statement of purpose(written by us)", "CV/Resume", "English Test (IELTS)"],
      bgColor: "#1FAF38"
   },
   {
      title: "POSTGRADUATE (PHD)",
      offers: ["All requirements in (A & B)", "Masters certificate and transcript", "Research Proposal", "English Test (IELTS)"],
      bgColor: "#006B8B"
   }
]



const StudyAbroadCountry = () => {  
   const location = useLocation();
  const {country} = useParams()
  console.log(country)

  useEffect(() => {
      window.scrollTo(0, 0);
   }, [location])
 
   

  return (
    <div className='study-abroad'>
      {/* <header className='study-abroad-header container-fluid '>
      <header className='study-abroad-header container-fluid' style={{background: `linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(${country === "australia" ? Australia : country === "usa" ? USA : country === "poland" ? Poland : country === "canada" ? Canada : UK})`}}>
          
          <div className="container bg-danger">
            <button className="title-btn">{country === "australia" ? "Australia" : country === "usa" ? "USA" : country === "poland" ? "Poland" : country === "canada" ? "Canada" : "UK"}</button>
            
            <button className="btn-primary p-3">Test</button>
          </div>
      </header>  */}
      <section className="container-fluid pt-5 pb-5 benefits"  style={{background: `linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(${country === "australia" ? Australia : country === "usa" ? USA : country === "poland" ? Poland : country === "canada" ? Canada : UK})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
          <div className="container" style={{position: "relative"}}>
            <button className="title-btn benefit-btn">{country === "australia" ? "Australia" : country === "usa" ? "USA" : country === "poland" ? "Poland" : country === "canada" ? "Canada" : "UK"}</button>
             <div className="row">
             
             </div>
          </div>
      </section>
      <section className="container-fluid study-abroad-introduction py-5">
        <div className="container">
           <div className="row">
              <div className="col-md-10 mx-auto text-start text-md-center">
                 <div className="text-center">
                    <h3>Introduction</h3>
                 </div>
                 {
                  country === "canada" && <p className=''>Canada offers international students an academic experience that is very hard to beat. Academic excellence, affordability, work opportunities during and post-study, pathway to permanent residence, and a safe living environment are just some of the reasons why Canada is ranked amongst the top ten destinations for students looking to study abroad.</p>
                 }

                 {
                  country === "uk" && <p>The United Kingdom (UK) has a longstanding reputation for academic excellence. Home to the world's oldest university in the English-speaking world, the UK attracts over 500,000 international students annually. In fact, the UK is amongst the top three countries students choose for international education around the world.</p>
                 }

                 {
                  country === "poland" && <p>There are over 400 universities and colleges in Poland which welcome around 1.5 million students annually. Poland is known to be an affordable European country, with a rich history and numerous high-quality university degrees offered in the English language.</p>
                 }

                 {
                  country === "australia" && <p>Australia is the third most popular study abroad destination in the world after the United States of America (USA) and the United Kingdom (UK). It offers an unbeatable study and living experience where students have the opportunity to get a world-class education, gain meaningful employment experience during and post-study, and enjoy an excellent quality of life, all in one place.</p>
                 }

                 {
                  country === "usa" && <p>Home to half of the world's top universities, the United States of America (US) is one of the most popular study destinations for international students. Over a million students from around the world study in the US every year, drawn to the high quality of education, highly accredited professors, and the high standard of living.</p>
                 }
              </div>
           </div>
        </div>
      </section>

      <section className="container-fluid study-abroad-card-section pt-sm-5 pb-5">
         <div className="container">
             <div className="row g-3">
               {
                  country === "australia" && (
                     australiaData.map((eachCard, i) => (
                        <div className="col-lg-4 col-md-7 col-sm-9 mx-auto country-card-box" key={i}>
                           <div className="country-card p-3 h-100" style={{backgroundColor: `${eachCard.bgColor}`, color: "#fff"}}>
                              <div className="country-card-header mb-2">
                                 <div className="d-flex align-items-center gap-3">
                                    <img src={Hat} alt="graduation cap" />
                                    <div>
                                       <h4 className='mb-0'>{eachCard.title}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="country-card-body">
                                 <div>
                                    <ul> 
                                       {
                                          eachCard.offers.map((eachOffer, i) => (
                                             <li key={i}>{eachOffer}</li>
                                          ))
                                       }
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  )
               }
               {
                  country === "poland" && (
                     polandData.map((eachCard, i) => (
                        <div className="col-lg-4 col-md-7 col-sm-9 mx-auto country-card-box" key={i}>
                           <div className="country-card p-3 h-100" style={{backgroundColor: `${eachCard.bgColor}`, color: "#fff"}}>
                              <div className="country-card-header mb-2">
                                 <div className="d-flex align-items-center gap-3">
                                    <img src={Hat} alt="graduation cap" />
                                    <div>
                                       <h4 className='mb-0'>{eachCard.title}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="country-card-body">
                                 <div>
                                    <ul> 
                                       {
                                          eachCard.offers.map((eachOffer, i) => (
                                             <li key={i}>{eachOffer}</li>
                                          ))
                                       }
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  )
               }
               {
                  country === "canada" && (
                     canadaData.map((eachCard, i) => (
                        <div className="col-lg-4 col-md-7 col-sm-9 mx-auto country-card-box" key={i}>
                           <div className="country-card p-3 h-100" style={{backgroundColor: `${eachCard.bgColor}`, color: "#fff"}}>
                              <div className="country-card-header mb-2">
                                 <div className="d-flex align-items-center gap-3">
                                    <img src={Hat} alt="graduation cap" />
                                    <div>
                                       <h4 className='mb-0'>{eachCard.title}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="country-card-body">
                                 <div>
                                    <ul> 
                                       {
                                          eachCard.offers.map((eachOffer, i) => (
                                             <li key={i}>{eachOffer}</li>
                                          ))
                                       }
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  )
               }
               {
                  country === "uk" && (
                     ukData.map((eachCard, i) => (
                        <div className="col-lg-4 col-md-7 col-sm-9 mx-auto country-card-box" key={i}>
                           <div className="country-card p-3 h-100" style={{backgroundColor: `${eachCard.bgColor}`, color: "#fff"}}>
                              <div className="country-card-header mb-2">
                                 <div className="d-flex align-items-center gap-3">
                                    <img src={Hat} alt="graduation cap" />
                                    <div>
                                       <h4 className='mb-0'>{eachCard.title}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="country-card-body">
                                 <div>
                                    <ul> 
                                       {
                                          eachCard.offers.map((eachOffer, i) => (
                                             <li key={i}>{eachOffer}</li>
                                          ))
                                       }
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  )
               }
               {
                  country === "usa" && (
                     usaData.map((eachCard, i) => (
                        <div className="col-lg-4 col-md-7 col-sm-9 mx-auto country-card-box" key={i}>
                           <div className="country-card p-3 h-100" style={{backgroundColor: `${eachCard.bgColor}`, color: "#fff"}}>
                              <div className="country-card-header mb-2">
                                 <div className="d-flex align-items-center gap-3">
                                    <img src={Hat} alt="graduation cap" />
                                    <div>
                                       <h4 className='mb-0'>{eachCard.title}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="country-card-body">
                                 <div>
                                    <ul> 
                                       {
                                          eachCard.offers.map((eachOffer, i) => (
                                             <li key={i}>{eachOffer}</li>
                                          ))
                                       }
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  )
               } 
             </div>
         </div>
      </section>
    </div>
  )
}

export default StudyAbroadCountry