import React from 'react' 
import Book from '../assets/images/book.png'
import Person from '../assets/images/person.png'
import GraduationCap from '../assets/images/school.png'
import BigButton from './BigButton'
// import Button from './Button'

const Stats = () => {
  return (
    <section className="container-fluid py-5 stats-section">
        <div className="container">
            <div className="row g-3">
                <div className="col-md-10 mx-auto">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12 mb-4 mb-sm-3 mx-auto">
                            <div className='stats-section-box justify-content-center d-flex gap-3 align-items-center'>
                                <div>
                                    <img src={GraduationCap} width="60px" alt="" />
                                </div>
                                <div>
                                    <h6 className='mb-2'>2000+</h6>
                                    <p>School Partnerships</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 mb-4 mb-sm-3 mx-auto">
                            <div className='stats-section-box justify-content-center d-flex gap-3 align-items-center'>
                                <div>
                                    <img src={Book} width="90%" alt="" />
                                </div>
                                <div>
                                    <h6 className='mb-2'>70,000+</h6>
                                    <p>Courses to attend</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 mb-4 mb-sm-3 mx-auto">
                            <div className='stats-section-box justify-content-center d-flex gap-3 align-items-center'>
                                <div>
                                    <img src={Person} width="60px" alt="" />
                                </div>
                                <div>
                                    <h6 className='mb-1'>1000+</h6>
                                    <p>Applicants <br /> 95% Acceptance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="text-center mt-3">
                <Button text="Get Access" bgColor="#006B8B" color="#fff" />
            </div> */}
            <div className='mt-3 mb-md-5 text-center'>
                <a href="https://portal.myadmissionlink.com/">
                    <BigButton text="Get Access" color="#FFF" bgColor="#006B8B" />
                </a>
            </div>
        </div>
    </section>
  )
}

export default Stats