import React from 'react' 
import Testimonial1 from '../assets/images/testimonials/testimonial1.jpeg'
import Testimonial2 from '../assets/images/testimonials/testimonial2.jpeg'
import Testimonial3 from '../assets/images/testimonials/testimonial3.jpeg'
import Testimonial4 from '../assets/images/testimonials/testimonial4.jpeg'
import Testimonial5 from '../assets/images/testimonials/testimonial5.jpeg'
import Testimonial6 from '../assets/images/testimonials/testimonial6.jpeg' 
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const TestimonialImg = [
    Testimonial1,
    Testimonial2,
    Testimonial3,
    Testimonial4,
    Testimonial5,
    Testimonial6,
]

const Testimonials = () => {
  return (
    <section className="container-fluid py-5 testimonials">
        <div className="container">
            <div className="testimonials-header col-lg-4 mb-5">
                <h2>Testimonials</h2>
                <p>What our customers say everyday.</p>
            </div>

             <div className="row testimonials-body">
                <div className="col-lg-7 col-xl-5 col-md-10 col-sm-11 mx-auto">
                    
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    grabCursor={true} 
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                      }}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >  
                    {
                        TestimonialImg.map((yt, i) => (
                            <SwiperSlide key={i} className="">
                            <div className='testimonials-body-box p-sm-5 pt-4 pb-4 px-2 text-center'>
                                    <div className="testimonials-image-box">
                                        <img src={yt} alt="" style={{width: "100%"}} />
                                    </div> 
                            </div>
                            </SwiperSlide>
                        ))
                    }
                    </Swiper>
                </div>
            </div> 
        </div>
    </section>
  )
}

export default Testimonials