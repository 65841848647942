import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../assets/images/logo.png'

const NavBar = ({openNav}) => {
  let activeStyle = {
    color: "#ECA633",
    textDecoration: "underline"
  }

  return (
    <nav className='nav container-fluid'>
        <div className="container d-flex justify-content-between align-items-center"> 
             <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>
             </div>
             <div className="nav-list d-none d-lg-block">
                <ul>
                    <li>
                      <NavLink
                          to="/" 
                          style={({ isActive }) => isActive ? activeStyle : undefined}>
                         Home
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                          to="/about" 
                          style={({ isActive }) => isActive ? activeStyle : undefined}>
                         About
                      </NavLink>
                    </li>

                    

                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle p-0" data-bs-toggle="dropdown" href={'#'} role="button" aria-expanded="false">Study Abroad</a>
                      <ul className="dropdown-menu sub-dropdown p-0">
                        <li className='m-0' style={{width: "100%"}}><Link to='/study-abroad/uk' className="dropdown-item">UK</Link></li>
                        <li className='m-0' style={{width: "100%"}}><Link to='/study-abroad/canada' className="dropdown-item">Canada</Link></li>
                        <li className='m-0' style={{width: "100%"}}><Link to='/study-abroad/poland' className="dropdown-item">Poland</Link></li> 
                        <li className='m-0' style={{width: "100%"}}><Link to='/study-abroad/usa' className="dropdown-item">USA</Link></li>
                        <li className='m-0' style={{width: "100%"}}><Link to='/study-abroad/australia' className="dropdown-item">Australia</Link></li> 
                      </ul>
                    </li> 

                    <li>
                      <NavLink
                          to="/processing" 
                          style={({ isActive }) => isActive ? activeStyle : undefined}>
                         Apply Now
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                          to="/evaluate" 
                          style={({ isActive }) => isActive ? activeStyle : undefined}>
                         Evaluation
                      </NavLink>
                    </li>

                    <li>
                      <a href="https://myadmissionlink.com/blog/" target='_blank'>Blog</a> 
                    </li>
 
                    <li>
                      <a href='https://portal.myadmissionlink.com/' target='_blank' >
                         <button>Portal</button>
                      </a>
                    </li>
                </ul>
             </div> 
             <div className='d-lg-none hamburg' onClick={openNav}>
                <span></span> 
                <span></span>
                <span></span>
            </div> 
        </div>
    </nav>
  )
}

export default NavBar