import React from 'react'
import BigButton from '../../../Components/BigButton'

const DirectVisaApplication = () => {
  return (
    <>
        <div className="apply-pay-box-header mb-5">
            <div>
                
                <h5 className='mb-2'>Make Purchase</h5>
                <div className="d-md-flex justify-content-between">
                    <div className='d-flex gap-3 align-items-center mb-3 mb-md-0'>
                        
                            
                            <div>
                                <h6>Statement of Purpose</h6> 
                            </div> 

                        
                    </div>
                    <div className='cash-box text-center'>
                        <span className='mb-0'>&#x20A6; 150,000
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="apply-pay-box-body">
            <div className="col-lg-7 col-xl-6 mx-auto d-flex justify-content-center text-center">
                <div> 
                    <ul className='col-lg-8 mx-auto ps-0' style={{listStyleType: "none"}}>
                         {
                            ["Access to our consultant", "Documentations", "Visa Interview Guidance", "Filling of Visa Application Forms", "Visa Explanation Letter", "Guidance on Proof of Fund", "Accomodation Booking/Hotel Reservation", "Flight Booking Guidance", "Assistance with Visa application fee payment", "Enrollment Guidance", "Pre departure and Briefing"].map((eachList, i) => (
                                <li key={i}>• &nbsp; {eachList}</li>
                            ))
                         }
                        
                    </ul>
 
                </div>
            </div>
        </div> 

        <div className='mt-5 text-center'>
            <a href="https://portal.myadmissionlink.com/">
                <BigButton text="Pay Now" color="#fff" bgColor="#1FAF38" />
            </a>
        </div>
    </>
  )
}

export default DirectVisaApplication