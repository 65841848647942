import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='error-page'>
        <div>
            <h2>Page Not Found</h2>
            <h6>Go back to <Link to='/'>Home</Link></h6>
        </div>
    </div>
  )
}

export default PageNotFound