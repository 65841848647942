import React from 'react' 
import ArrowRight from '../assets/images/arrow-right.png'
import Loader from './Loader'

const BigButton = ({text, bgColor, color, link, isSubmitting}) => {
  return ( 
        <button className='big-btn' style={{backgroundColor: `${bgColor}`, color: `${color}`, position: 'relative'}}>
            <span className='me-2'>{text}</span>
            <img src={ArrowRight} alt="" />

            {
              isSubmitting && <Loader />
            }
        </button> 
  )
}

export default BigButton